.custom-scrollbar {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888 #f1f1f1; /* For Firefox */
  }
  
  /* Webkit-based browsers (Chrome, Safari, Edge) */
  .custom-scrollbar::-webkit-scrollbar {
    width: 10px; /* Slim scrollbar width */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light track background */
    border-radius: 10px; /* Rounded track */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888; /* Darker scrollbar thumb */
    border-radius: 8px; /* Rounded scrollbar thumb */
    border: 2px solid #f1f1f1; /* Thumb padding */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Thumb color on hover */
  }
  