@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind components;
/* @tailwind utilities; */

@layer components {
    .all-\[unset\] {
        all: unset;
    }
}

:root {
    --gray: rgba(58, 58, 73, 1);
    --shadows: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}